a{
  text-decoration: inherit;
    color: inherit;
    cursor: pointer;
}

.contentContainer {
  position: absolute;
  /* margin: var(--navbar-height) 0; */
  top: var(--navbar-height);
  background-color: rgb(36, 179, 36);
  width: 100%;
  
  height: 100%;
  /* margin: 0 auto; */
}

.heroContainer {
  background-color: rgb(175, 175, 175);
  height: calc(100vh - var(--navbar-height) + 2px);
  /* height: 100vh; */
  width: 100%;
}

.heroMain{
  
  position: relative;
  top: 50%;
 
  transform: translateY(-50%);
}

.heroContents {
  max-width: var(--content-width);
  /* background-color: aquamarine; */
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  /* padding: 1rem; */
  /* flex-wrap: wrap; */
}

/* .heroLeft {
  width: 40%;
  display: flex;
  align-items: center;
  padding: 1rem;
  animation: float 5s ease-in-out infinite;
  perspective: 2000px;
} */


.heroLeft {
  width: 40%;
  display: flex;
  align-items: center;
  padding: 1rem;
  animation: float 5s ease-in-out infinite;
 
  perspective: 2000px;
  margin: 0 auto;
  height: 100%;
}


.cardInner{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border-radius: 1rem;
}

.cardInnerFlipped{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  border-radius: 1rem;
}

.heroImg1{
  display: flex;
  align-items: center;
  position: absolute;
  /* width: 100%; */
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  border-radius: 1rem;
  padding: 0 1rem;
  overflow: visible;
}

.heroImg2{
  display: flex;
  align-items: center;
  position: absolute;
  /* width: 100%; */
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  transform: rotateY(180deg);
  border-radius: 1rem;
  padding: 0 1rem;
  overflow: visible;
}

strong {
  position: relative;

  
}



.heroRight {
  width: 60%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding: 1rem;
}

.heroText1 {
  font-size: 1.5rem;
  font-weight: 400;
}
.heroText2 {
  font-size: 3.5rem;
  font-weight: 700;
  /* text-align: center; */
  margin-top: 1.5rem;
  line-height: 3rem;
}

.heroText3 {
  position: relative;
  margin-top: 4rem;
  font-size: 1.3rem;
  /* text-align: center; */
  font-weight: 300;
  line-height: 1.7rem;
  /* max-width: 80%; */
  /* border-left: 2px solid rgb(223, 223, 223); */
  padding-left: 2rem;
  padding-right: 1rem;
  color: rgb(70, 70, 70);
}

.heroText3border{
  border-left: 3px solid rgb(223, 223, 223);
  position: absolute;
  top: 0.4rem;
  bottom: 5%;
  left: 0rem;
}
.heroMoreBtn{
  
}
.heroMoreBtnTxt{
  background-color: rgb(77, 77, 77);
  padding: 0.7rem 0;
 
  margin-top: 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 0.5rem;
  color: white;
  
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 10rem;
  border: none;
  transition: 0.2s;
}
.heroBtnColor{
  background-color: rgb(226, 226, 226);
  color: rgb(61, 61, 61);
}
.heroMoreBtnTxt:hover{
  transform: translateY(-3px);
  box-shadow: rgb(0 0 0 / 15%) 0 3px 9px 0;
}


.heroProjectBtn{
  background-color: rgba(0, 0, 0, 0);
  padding: 0.7rem 0;
 
  margin-top: 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 0.5rem;
  color: white;
  
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 10rem;
  border: 3px solid rgb(77, 77, 77);
  color: rgb(77, 77, 77);
}

.heroProjectBtn:hover{
  
  padding: 0.7rem 0;
 
  margin-top: 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 0.5rem;
  color: white;
  
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 10rem;
  border: 3px solid rgb(202, 202, 202);
  background-color: rgb(202, 202, 202);
  color: white;
}

.heroBtnGroup{
  display: flex;
  gap: 1rem;
}

.heroImg {
  height: 50%;
  /* background-color: rgb(146, 146, 146); */
  width: 100%;
  display: flex;
  align-items: center;
  transition: transform 1s;
  
}
.heroImgFlip{
  transition: transform 1s;
  transform: rotateY(180deg);

}
.heroDownBtn{
    position: absolute;
    bottom: 2rem;
    /* margin: 0 auto; */
    width: 100%;
    display: flex;
    justify-content: center ;
}

.heroDownBtnText{
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    background-color: blanchedalmond;
    border-radius: 1rem;
}

.techContainer{
    min-height: 50vh;
    background-color: rgb(255, 255, 255);
    padding: 1rem 0;
}

.techList{
    display: flex;
    gap: 1rem;
    justify-content: center;
    opacity: 1;
    flex-wrap: wrap;
  margin-bottom: 2rem;
}
.techList2{
  display: flex;
  gap: 1rem;
  justify-content: center;
  
  flex-wrap: wrap;
margin-bottom: 2rem;
}

.techItem{
    background-color: rgb(236, 236, 236);
    width: 150px;
    height: 150px;
    opacity: 1;
    border-radius: 1rem;
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px; */
    transform: translateX(0);
    transition: all 100ms;
    
}
.techItem:nth-child(2){
  transition-delay: 200ms;
}
.techItem:nth-child(3){
  transition-delay: 300ms;
}
.techItem:nth-child(4){
  transition-delay: 400ms;
}
.techItem:nth-child(5){
  transition-delay: 500ms;
}
.techItem:nth-child(6){
  transition-delay: 600ms;
}
.techItem:nth-child(7){
  transition-delay: 700ms;
}
.techItem:nth-child(8){
  transition-delay: 800ms;
}
.techItem:nth-child(9){
  transition-delay: 900ms;
}


.techItemOff{
  transform: translateX(-100%);
  
  opacity: 0;
}

.techItemContent{
  padding: 1rem;
  font-size: 0.8rem;
}
.techItemContent1{
  padding: 1rem;
  font-size: 0.8rem;
}
.techItemLine1{
  display: flex;
  justify-content: center;
}
.techItemLine2{
  
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(61, 61, 61);
}
.techItemLine3{

  
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: rgb(61, 61, 61);
}

.techContents{
    max-width: var(--content-width);
    background-color: rgb(255, 255, 255);
    margin: 0 auto;
    height: 100%;
    display: flex;
    padding: 2.5rem 0;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 0;
}
.techContents2{
  max-width: var(--content-width);
  background-color: rgb(255, 255, 255);
  margin: 0 auto;
  height: 100%;
  display: flex;
  padding: 2.5rem 0;
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

}

.show{
  opacity: 1;
}

/* .techContents{
    display: flex;
    flex-direction: column;
    
} */

.techText1{
    /* height: 200px; */
    padding: 2rem;
    font-size: 2rem;
    margin: 0 auto;
    /* margin-bottom: 3rem; */

}
.techText2{
  /* height: 200px; */
  padding: 2rem;
  font-size: 2rem;
  margin: 0 auto;
  /* margin-bottom: 3rem; */
background-color: rgb(255, 255, 255);
}

.eduContainer{
  min-height: 50vh;
    background-color: rgb(238, 238, 238);
}
.eduContents{
  max-width: var(--content-width);
  
  margin: 0 auto;
  height: 100%;
  display: flex;
  /* padding: 1rem; */
  /* position: relative; */
  display: flex;
  flex-direction: column;
  padding: 5rem 1rem;
  gap: 2rem;
}

.eduBoxContent{
  padding: 2rem;
}

.eduBoxHeader{
  font-size: 2rem;
  margin-bottom: 2rem;
}

.image{
  width: 100%;
overflow: hidden;
border-radius: 2rem;
box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
cursor: pointer;
-webkit-tap-highlight-color: transparent;
}

.eduBox{
  background-color: white;
  width: 100%;
  margin: 0 auto;
  /* padding: 1rem; */
  /* height: 20rem; */
  
  border-radius: 2rem;
}
.projectContainer{
  min-height: calc(100vh - var(--navbar-height));
  background-color: rgb(131, 131, 131);

}
.projectContents{
  max-width: var(--content-width);
  
  margin: 0 auto;
  height: 100%;
  padding: 5rem 1rem;
  /* background-color: red; */
}
.projectText1{
 
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: white;
  display: flex;
  justify-content: center;
}
.projectBox{
  /* background-color: green; */
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
  /* border-radius: 1.5rem; */
  /* overflow: hidden; */
}

.projectBox2{
  /* background-color: green; */
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
  border-radius: 1.5rem;
  overflow: hidden;
}

.projectBox1{
  /* background-color: green; */
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 3rem;
}

.timelineContainer{
  width: 100%;
  right: 0;
  margin: 0 auto;
  /* background-color: blue; */

  border-left: 6px solid rgb(183, 183, 183);
}

.eduBoxInfo{
  height: 100%;
  padding-left: 1rem;
}

.projectBoxImg{
  width: 50%;
  padding: 1rem;
  /* background-color: rgb(135, 135, 135); */
  /* margin: auto 0; */
  padding-top: 0;
  padding-left: 0;
}
.projectBoxImg2{
  width: 50%;
  padding: 1rem;
  /* background-color: rgb(135, 135, 135); */
  
}

.projectImg{
  width: 100%;
  display: block;
  border-radius: 1rem;
}

.projectBoxContent{
  max-width: 50%;
  background-color: rgb(241, 241, 241);
  padding: 2rem 2rem;
  color: rgb(66, 66, 66);
border-radius: 1rem;
}
.projectBoxHeader{
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.projectBoxHeader span:nth-child(2){
  font-size: 1.8rem;
  font-weight: 600;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.projectBoxText1{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}
.projectBoxTags{
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
 color: white;
 font-size: 0.9rem;
 /* justify-content: center; */
}
.projectBoxTags span{
  background-color: black;
  padding: 0.3rem 1rem;
  

}
.projectBoxButtonGroup{
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}
.projectBoxButtonGroup button{
  border: 2px solid rgb(38, 38, 38);
  background-color: rgb(255, 255, 255);
  padding: 0.5rem 1rem;
  font-size: 1rem;
 
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background: none;
  width: calc((calc(100%/3)) - calc(2rem/3));
  transition: 0.3s;
}
.projectBoxButtonGroup button:hover{
  background-color: white;
}

.projectBoxButtonGroup button:nth-child(3){
  border: 2px solid rgb(48, 48, 48);
  /* background-color: rgb(200, 200, 200); */
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background: none;
  background-color: rgb(48, 48, 48);
  color: white;
 
}
.projectBoxButtonGroup button:nth-child(3):hover{
  background-color: rgb(0, 0, 0);
  border: 2px solid rgb(0, 0, 0);
}









.timeline1{
 
  background-color: rgb(255, 255, 255);
  margin-bottom: 2rem;
  margin-left: 2rem;
  border-radius: 1rem;
  position: relative;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}



.timeline1::before{
  background-color: green;
}

.timelineHeader{
  background-color: rgb(52, 52, 52);
  color: white;

  padding: 0.5rem 1rem;
  display: inline-block;
margin-bottom: 0.5rem;
}
.timelineText1{
  font-size: 1.3rem;
  font-weight: 600;
  /* margin-bottom: 0.5rem; */
}
.timelineText2{
  font-size: 1rem;
 
  margin-bottom: 1rem;
}
.timelineText3{
  font-size: 1rem;
 display: flex;
 flex-direction: column;
 margin-bottom: 1rem;
}
.timelineText3Custom{
  font-size: 1rem;
 display: flex;
 flex-direction: column;
 margin-bottom: 1rem;
}
.timelineText3 span:nth-child(1){
 
  margin-bottom: 0.3rem;
  font-weight: 500;
}
.timelineText3 span:nth-child(2){
  font-weight: 500;
  font-size: 1rem;
  
  margin-bottom: 0.5rem;
  display: inline;
 
}
.timelineText3 span:nth-child(3){
  font-weight: 500;
  font-size: 1rem;
  /* font-style: italic; */
  margin-bottom: 0.5rem;
 
}
.timelineText3 span:nth-child(4){
  font-weight: 500;
  font-size: 1rem;
  /* font-style: italic; */
  margin-bottom: 0.5rem;
 
}
.timelineText3Custom span:nth-child(1){
 
  margin-bottom: 1rem;
  /* font-weight: 500; */
}
.timelineText3Custom span:nth-child(2){
 
  margin-bottom: 0.5rem;
  /* font-weight: 500; */
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.timelineText3Custom span:nth-child(3){
 
  margin-bottom: 0.5rem;
  /* font-weight: 500; */
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.timelineText3Custom span:nth-child(4){
 
  
  /* font-weight: 500; */
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.timelineText3Icon{
  width: 2rem;
}
.timelineTag{
  background-color: rgb(212, 212, 212);
  padding: 0.3rem 0.5rem;
  font-weight: 500;
  display: inline-block;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 1rem;
margin-bottom: 0.5rem;
}

.horizontalDivider{
  width: 100%;
  border-bottom: 1px solid rgb(208, 208, 208);
  margin:1.5rem 0;
}

.timelineButtonGroup{
  border: none;
  display: flex;
  gap:0.5rem;
}

.timelineButtonGroup button{
  border: none;
  padding: 0.5rem 2rem;
font-size: 0.9rem;
display: flex;
align-items: center;
justify-content: center;
gap: 0.5rem;
}

.timelineBall{
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  background-color: rgb(225, 225, 225);
  top: 0;
  left: -2.93rem;
  border: 3px solid white;
}

.footerContainer{
  
    background-color: rgb(58, 58, 58);
}
.footerContents{
  max-width: var(--content-width);
  color: white;
  margin: 0 auto;
  height: 100%;
 
  padding: 1rem 2rem;
  /* padding: 1rem; */
  /* position: relative; */
  /* display: flex;
  flex-direction: column;
  padding: 5rem 1rem;
  gap: 2rem; */
}

.footerLine1{
display: flex;
flex-wrap: wrap;
margin-bottom: 1rem;
}

.footerLine1 span{
  margin-right: 2.5rem;
  font-weight: 300;
  cursor: pointer;
}

.footerLine1 div{
  display: flex;
  flex-wrap: wrap;
}

.footerLine2{
  display: flex;
flex-wrap: wrap;
}

.footerLine2 span{
  margin-right: 2.5rem;
  font-weight: 300;
}

.demoPlay{
  background-color: rgb(255, 160, 160);
  font-size: 1rem;
 display: flex;
 gap: 0.7rem;
 padding: 0.1rem 1rem;
 align-items: center;
 color: rgb(255, 255, 255);
 border-radius: 0.3rem;
 height: 1.8rem;
 cursor: pointer;
}
/* .footerLine1 span:nth-child(1){
  font-weight: 600;
} */

/* .footerLine1{
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
}
.footerLine2{
  font-size: 0.9rem;
  font-weight: 300;
  display: flex;
  gap: 1rem;

}


.footerLine3{
 font-weight: 600;
  margin-bottom: 0.2rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  align-content: flex-start;
}
.footerLine4{
  font-size: 0.9rem;
  font-weight: 300;
  display: flex;
  gap: 2rem;
 
  flex-wrap: wrap;
  
} */
.modalOverlay{
  background-color: black;
  height: 100%;
}

.individualProjectContainer{
  height: 100%;
    margin: 0 auto;
    max-width: var(--content-width);
    padding: 2rem 1rem;
}

.individualContainerMain{
  background-color: #838383;
 min-height: 100vh;
  position: absolute;
  
  width: 100%;

}

@media (max-width: 1000px){
  .heroText1 {
    font-size: 1.5rem;
    font-weight: 400;
  }
  .heroText2 {
    font-size: 2.5rem;
    font-weight: 700;
    /* text-align: center; */
    margin-top: 1.2rem;
    line-height: 2.5rem;
  }
  
  .heroText3 {
    margin-top: 2.5rem;
    font-size: 1.1rem;
    /* text-align: center; */
    font-weight: 300;
    line-height: 1.5rem;
  }

  .projectBoxButtonGroup button:nth-child(1){
    width: calc(50% - 0.5rem);
  }
  .projectBoxButtonGroup button:nth-child(2){
    width: calc(50% - 0.5rem);
  }
  .projectBoxButtonGroup button:nth-child(3){
    width: 100%;
  }
}

@media (max-width: 800px){
  .heroContents{flex-direction: column;
    align-items: center;
    /* justify-content: center; */

}
.heroLeft{
  /* width: 80%; */
  height: 50%;
  margin: 0 auto;
  /* padding: 0; */
}
.heroRight{
  width: 100%;
  padding: 1.5rem 2rem;
}

.projectBox{
  flex-direction: column;
  width: 100%;
}
.projectBoxImg{
width: 100%;
padding-right: 0;
}
.projectBoxContent{
  max-width: 100%;
  /* background-color: red; */
}
.projectBoxButtonGroup button:nth-child(1){
  width: calc(50% - 0.5rem);
}
.projectBoxButtonGroup button:nth-child(2){
  width: calc(50% - 0.5rem);
}
.projectBoxButtonGroup button:nth-child(3){
  width: 100%;
}
.image{
 width: 100%;

border-radius: 2rem;
box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
object-fit: cover;
}
}
@media (max-width: 500px){
  .heroText1 {
    font-size: 1.5rem;
    font-weight: 400;
  }
  .heroText2 {
    font-size: 2.3rem;
    font-weight: 700;
    /* text-align: center; */
    margin-top: 1.2rem;
    line-height: 2.2rem;
  }
  
  .heroText3 {
    margin-top: 2rem;
    font-size: 1.1rem;
   
    font-weight: 300;
    line-height: 1.5rem;
  }
  .heroLeft{
    width: 100%;
    height: 45%;
    margin: 0 auto;
  }
  .image{
    width: 100%;
   
   border-radius: 2rem;
   box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
   object-fit: cover;
   }
   .projectBoxContent{
    padding: 1rem;
    font-size: 0.9rem;
  }
  .projectBoxHeader{
    margin-bottom: 1rem;
  }
  .projectBoxText1{
    margin-bottom: 1.5rem;
  }
  .projectBoxTags{
    margin-bottom: 1.5rem;
    
  }
  .eduBoxContent{
    padding: 2rem 1rem;
  }
  .footerLine1 span{
    margin-right: 1rem;
  }
}

@media (max-width: 400px){
  .heroText1 {
    font-size: 1.2rem;
    font-weight: 400;
  }
  .heroText2 {
    font-size: 1.7rem;
    font-weight: 700;
    /* text-align: center; */
    margin-top: 1rem;
    line-height: 1.5rem;
  }
  
  .heroText3 {
    margin-top: 1.1rem;
    font-size: 0.9rem;
   
    font-weight: 300;
    line-height: 1rem;
  }
  .heroLeft{
    width: 75%;
    height: 40%;
    margin: 0 auto;
  }
  .image{
    width: 100%;
   
   border-radius: 2rem;
   box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
   object-fit: cover;
   }
   .heroMoreBtnTxt{
    
   
    margin-top: 1rem;
   
  }

  
  .heroProjectBtn{
    margin-top: 1rem;
  }
}

@keyframes float {
	0% {
		/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
		transform: translatey(0px);
	}
	50% {
		/* box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2); */
		transform: translatey(10px);
	}
	100% {
		/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
		transform: translatey(0px);
	}
}