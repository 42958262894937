@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap');
/* .contentContainer{
position: absolute;

  top: var(--navbar-height);
  background-color: green;
  width: 100%;
  height: 100%;
 
} */

.App{
  max-width: 100vw;
}

.menuOverlay{
  background-color: rgba(0, 0, 0, 0.682);
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: flex-end;
  top: 0;
}
.hidden{
  display: none;
}

.menuContainer{
  width: 65%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  
  

  animation-name: menumove;
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(.42, 0, .58, 1);
}

@keyframes menumove{
  0% {transform: translateX(200px); opacity: 0;}
  
  100% {transform: translateX(0px); opacity: 1;}
}
.menuContainerOff{
  width: 65%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  transform: translateX(100px);
  transition: all 5000ms;
  opacity: 0;
}
.menuContainerTop{
  height: var(--navbar-height);
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.menuContainerContent{
padding: 1rem;
}

.menuContainerItem{
  padding: 1rem;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  cursor: pointer;
}

.menuContainerItem:hover{
  color: #5fc4ff;
  transform: translateY(-2px);
}
.modalOverlay{
  background-color: rgba(0, 0, 0, 0.656);
  height: 100%;
  width: 100%;
  z-index: 9999;
  position: fixed;
  top: 0;
 
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer{
width: min(95%, 800px);
min-height: 400px;
background-color: white;
margin: 0 auto;
padding: 1.5rem ;
border-radius: 0.5rem;
}

.modalContainer2{
  width: min(95%, 400px);
  min-height: 300px;
  background-color: white;
  margin: 0 auto;
  padding: 1.5rem ;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  }

.modalText1{
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

}

.modalText1 span:nth-of-type(2){
  
  width:1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3rem;
}
.modalText1 span:nth-of-type(2):hover{
  background-color: rgb(238, 238, 238);
}



.modalContent{
  display: flex;
  gap: 2rem;
}
.modalContent1{
  width: 50%;
 
 
}

.modalContent3{
 
 
 height: 100%;
 flex: 1;
 display: flex;
 flex-direction: column;
 justify-content: center;
 gap: 1rem;
}

.modalContent1::after{
  content: "";
    display: block;
  border-right: 1px solid black;
}
.modalContent2{
  width: 50%;
  
}

.modalContent1Img{
  width: 60%;
  margin: 0 auto;
}
.qrImage{
  width:100%;
  
}

.modalContent1Text1{
  margin-bottom: 1rem;
}
.modalContent2Text1{
  margin-bottom: 1rem;
}

.liveSiteBtn{
  font-size: 1rem;
  width: 100%;
  border: none;
  padding: 0.7rem 0;
  margin-bottom: 2rem; 
  background-color: rgb(77, 77, 77);
  
   
    font-weight: 600;
    border-radius: 0.5rem;
    color: white;
    display: flex;
    
    align-items: center;
    justify-content: center;
    
   
}

.resumeOptionBtn{
  font-size: 1rem;
  width: 100%;
  border: none;
  padding: 0.9rem 0;
 
  background-color: rgb(77, 77, 77);
  
   
    font-weight: 600;
    border-radius: 0.5rem;
    color: white;
    display: flex;
    
    align-items: center;
    justify-content: center;
}

.liveSiteBtn:hover{
  background-color: rgb(48, 48, 48);
}
.resumeOptionBtn:hover{
  background-color: rgb(48, 48, 48);
}

.accountPwContainer{
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 12px;
  padding: 1rem;
  border-radius: 1rem;
}

.accountPwContainer{
  display: flex;
  flex-direction: column;
  
}

.accountPwContainer span:first-of-type{
  background-color: rgb(143, 202, 229);
  margin-bottom: 0.5rem;
  padding: 0.2rem 1rem;
  width: 10rem;
  color: white;
  border-radius: 0.5rem;
  text-align: center;
  font-weight: 600;
}

.accountPwContainer span:nth-of-type(2){
  margin-bottom: 1rem;
}

.accountPwContainer span:nth-of-type(3) {
  font-size: 0.9rem;
  color: rgb(107, 107, 107);
}

.accountPwContainer span:nth-of-type(4) {
  font-size: 0.9rem;
  color: rgb(107, 107, 107);
  margin-bottom: 1rem;
}
.accountPwContainer span:nth-of-type(5) {
  font-size: 0.9rem;
  color: rgb(107, 107, 107);
}
.accountPwContainer span:nth-of-type(6) {
  font-size: 0.9rem;
  color: rgb(107, 107, 107);
}

@media (max-width: 800px){
 
  .modalContent1{
      display: none;
  }
  .modalContent2{
    width: 100%;
  }
}