body {

  font-family:'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
/* background-color: red; */
margin: 0;

--navbar-height: 60px;
--content-width: 1280px;
max-width: 1280px;
/* overflow-anchor: none; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .App{
  background-color: black;
} */

* { 
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
   box-sizing: border-box; 
}

html {
  scroll-behavior: smooth;
}

button{
  cursor: pointer;
}

:target {
  scroll-margin-top: 70px;
}